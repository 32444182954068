<template>
  <!--取消审核-->
  <div style="padding: 20px;">
    <div>
      <div class="title">
        {{ $route.meta.title }}
      </div>

      <!--搜索条件-->
      <el-card shadow="never" style="margin-top: 20px;">
        <div class="card">
          <div class="card_t">
            <div class="card_t_icon"></div>
            <div class="card_t_text">
              筛选条件
            </div>
          </div>
          <div class="card_b">
            <!-- 搜索表单 -->
            <el-form
              :model="where"
              label-width="90px"
              class="ele-form-search"
              @keyup.enter.native="reload"
              @submit.native.prevent>
              <el-row :gutter="15">
                <el-col :lg="6" :md="12">
                  <el-form-item label="关键字:">
                    <el-input
                      clearable
                      v-model="where.keyword"
                      placeholder="订单号/案件号/客户/客户电话/客户/服务人员"/>
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="12">
                  <el-form-item label="救援类型:">
                    <el-select
                      clearable
                      v-model="where.rescue_type"
                      placeholder="请选择"
                      class="ele-fluid">
                      <el-option v-for="(item) in ServicesAvailable" :label="item.label" :value="item.serial_number"/>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :lg="8" :md="12">
                  <el-form-item label="下单时间:">
                    <el-date-picker
                      value-format="yyyy-MM-dd"
                      v-model="where.order_time"
                      type="daterange"
                      unlink-panels
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期">
                    </el-date-picker>
                    <!--<DatePicker v-model="where.order_time" :transfer="true" @on-change="datechange" format="yyyy-MM-dd" type="daterange" placement="bottom-end" placeholder="请选择"/>-->
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="结算方式:">
                    <el-select
                      clearable
                      v-model="where.authentication_status"
                      placeholder="请选择"
                      class="ele-fluid">
                      <el-option
                        v-for="(item) in dict.type.closeanaccount_type"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="15">
                <el-col :span="6">
                  <el-form-item label="是否事故:">
                    <el-select
                      clearable
                      v-model="where.authentication_status"
                      placeholder="请选择"
                      class="ele-fluid">
                      <el-option
                        v-for="(item) in dict.type.is_accident"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="派单方式:">
                    <el-select
                      clearable
                      v-model="where.authentication_status"
                      placeholder="请选择"
                      class="ele-fluid">
                      <el-option
                        v-for="(item) in dict.type.sendorders_type"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="服务区域:">
                    <el-cascader
                      ref="refCascader"
                      :options="city"
                      v-model="where.serve_district"
                      @change="handleChange"></el-cascader>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <div class="ele-form-actions">
                    <el-button
                      type="primary"
                      class="ele-btn-icon"
                      @click="QueryList">查询
                    </el-button>
                    <el-button @click="empty">清空</el-button>
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </el-card>

      <!--数据表格-->
      <el-card shadow="never">
        <div class="card">
          <div class="Datalist">
            <div class="Datalist_l">
              <div class="Datalist_icon"></div>
              <div class="Datalist_text">
                数据列表
              </div>
              <!--<div class="Datalist-botton" style="margin-left: 20px">-->
              <!--  <el-button class="custom-button" @click="CreatePenaltyOrder()">-->
              <!--    &lt;!&ndash;el-icon-devops 是自己命名的&ndash;&gt;-->
              <!--    <i class="el-icon-xiadan" />-->
              <!--    <span style="vertical-align: middle">下单</span>-->
              <!--  </el-button>-->
              <!--</div>-->
            </div>
            <div>
              <!--<el-button>导出数据</el-button>-->
              <!--<el-button @click="ListSetup()">列表设置</el-button>-->
            </div>
          </div>

          <div class="card_b">
            <!-- 数据表格 -->
            <my-table
                ref="myTable"
                :columns="columns"
                :tableData="List"
                :cellStyle="cellStyle"
                :headerCellStyle="headerCellStyle"
                :rowClassName="rowClassName"
                :total="total"
                :customsFromWhere="where"
                :loading="loading"
                @select="select"
                @ChangeSize="ChangeSize"
                @currentChange="currentChange"
            >

              <template v-slot:photo_integrity= "{ scope }">
                <div>
                  <img src="@/assets/images/home/order/chenggong.png" alt="" style="width: 24px;height: 24px;" v-if="scope.row.photo_integrity == true">
                  <img src="@/assets/images/home/order/shibai.png" alt="" style="width: 24px;height: 24px;" v-else>
                </div>
              </template>
              <template v-slot:work_info= "{ scope }">
                <div>
                  <img src="@/assets/images/home/order/chenggong.png" alt="" style="width: 24px;height: 24px;" v-if="scope.row.work_info == true">
                  <img src="@/assets/images/home/order/shibai.png" alt="" style="width: 24px;height: 24px;" v-else>
                </div>
              </template>
              <template v-slot:practical_track= "{ scope }">
                <div>
                  <img src="@/assets/images/home/order/chenggong.png" alt="" style="width: 24px;height: 24px;" v-if="scope.row.practical_track == true">
                  <img src="@/assets/images/home/order/shibai.png" alt="" style="width: 24px;height: 24px;" v-else>
                </div>
              </template>

              <!-- 不使用数据可不接收scope值 -->
              <template v-slot:operationSlot= "{ scope }">
                <el-link :underline="false" type="warning" @click="ToExamine()" v-auths="[`${$config.uniquePrefix}orderModule:cancelApproval:audit`]">审核</el-link>
              </template>
            </my-table>
          </div>
        </div>
      </el-card>

      <!--列表设置弹窗-->
      <div class="dialog">
        <el-dialog
            title="列表设置"
            :visible.sync="ListSetupShow"
            width="60%"
            center
            :before-close="handleClose">
        <span>
           <PackageSortElement :AllParameters="columns"   @change="SortList" @show="dialogshow"></PackageSortElement>
        </span>
        </el-dialog>
      </div>
    </div>

    <!-- 订单审核弹窗 -->
    <orderReview-edit :data="orderReviewcurrent" :visible.sync="showorderReview" />

  </div>
</template>

<script>
//引入订单审核弹窗组件
import OrderReviewEdit from './components/orderReview-edit.vue'

// 引入表格设置封装的组件
import PackageSortElement from "@/components/PackageSortElement";
// 权限
import {ShowTable} from '@/utils'
// 引入的接口
import {
  getfacilitatorApi,
  set_statusApi
} from '@/api/custom'
import {getcityList, getget_services_available} from "@/api/yunli";
import {order_list_header} from '@/api/orderModule'
import Vue from "vue";

export default {
  // 组件生效
  components: {
    PackageSortElement,
    OrderReviewEdit
  },
  data(){
    return{
      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 5 // 每页显示条目个数
      },
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading:true,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true
        },
        {
          label: "订单号",
          // type: "index",
          width: "110",
          prop: "order_id",
          fixed:'left',
          isShow: true
        },
        {
          label: "案件号",
          // type: "index",
          // width: "70"
          prop: "case_number",
          isShow: true
        },
        {
          label: "故障车牌",
          prop: "license_plate",
          isShow: true
        },
        {
          label: "救援类型",
          prop: "items_name",
          isShow: true
        },
        {
          label: "客户",
          prop: "owners",
          isShow: true
        },
        {
          label: "订单状态",
          prop: "status_label",
          isShow: true
        },
        {
          label: "去程",
          prop: "practical_arrival_mileage",
          isShow: true
        },
        {
          label: "背程",
          prop: "practical_return_mileage",
          isShow: true
        },
        {
          label: "计价方式",
          prop: "valuation_mode",
          isShow: true
        },
        {
          label: "合同价",
          prop: "contract_price",
          isShow: true
        },
        {
          label: "超基础价",
          prop: "exceed_base_price",
          isShow: true
        },
        {
          label: "订单补扣款",
          prop: "repair_thread_price",
          isShow: true
        },
        {
          label: "应付款",
          prop: "receivable_amount",
          isShow: true
        },
        {
          label: "照片完整",
          prop: "photo_integrity",
          slot: 'photo_integrity',
          isShow: true
        },
        {
          label: "工单完整",
          prop: "work_info",
          slot: 'work_info',
          isShow: true
        },
        {
          label: "轨迹完整",
          prop: "practical_track",
          slot: 'practical_track',
          isShow: true
        },
        {
          label: "派单方式",
          prop: "send_orders_type",
          isShow: true
        },
        {
          label: "下单时间",
          prop: "order_time",
          isShow: true
        },
        {
          label: "发包方",
          prop: "channel_name",
          isShow: true
        },
        // {
        //   label: "操作",
        //   width: "150",
        //   fixed:'right',
        //   slot: "operationSlot",
        //   isShow: true
        // },
      ],
      // 列表设置弹窗
      ListSetupShow:false,

      //订单审核
      orderReviewcurrent:null,
      //审核弹窗
      showorderReview:false,

      //字典
      dict: {
        type:{}
      },

      //服务项目
      ServicesAvailable:[],
      //城市列表
      city:[],

    }
  },

  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    // 初始化dict
    this.$store.getters.dict.forEach(item => {
      if (item.name === '订单字典'){
        item.children.forEach(res => {
          Vue.set(this.dict.type, res.code, res.withChildren);
        })
      }
    });

      // 调用获取自定义表头
      this.getHeader();

    //调用获取客户信息列表方法
    this.getList();

    //获取服务项目
    this.getFuwuxiangmu();
    //调用获取城市列表
    this.getCity();

  },

  methods:{
    //获取服务项目
    getFuwuxiangmu(){
      getget_services_available().then(res => {
        this.ServicesAvailable = res.data;
      })
    },

    // 获取城市列表
    getCity(){
      getcityList().then(res => {
        // console.log(res);
        this.city = res.data;
      })
    },

    // 点击处罚按钮按钮
    CreatePenaltyOrder(){
      // 显示创建订单模块
      this.showEdit = true;
    },

      // 获取自定义表头
      getHeader() {
          order_list_header().then(res => {
              // console.log(res)
              // console.log(this.columns)
            // push表头
            res.data.forEach(item => {
              let data = {
                label: item.label,
                prop: item.field,
                isShow: true
              }
              if (this.columns.some(item => JSON.stringify(data) === JSON.stringify(item))) {
                console.log('数组中存在该对象');
              } else {
                console.log('数组中不存在该对象');
                this.columns.push(data)
              }
            })

            // 判断下是否存在了操作列 不存在则追加
            // 使用includes()方法进行判断
            // push操作列
            let data1 = {
              label: "操作",
              width: "200",
              slot: "operationSlot",
              fixed:'right',
              isShow: true
            }
            if (this.columns.some(item => JSON.stringify(data1) === JSON.stringify(item))) {
              console.log('数组中存在操作列');
            } else {
              console.log('数组中不存在操作列');
              this.columns.push(data1)
            }

          })
      },

    // 获取列表
    getList(){
      getfacilitatorApi(this.where).then(async (res) => {
        this.total = res.data.count;
        // this.List = res.data.list;
        this.loading = false;
      }).catch((res) => {
        this.$Message.error(res.msg);
        this.loading = false;
      })
    },

    //选择时间
    datechange(e){
      this.where.order_time = e;
    },

    // 查询条件
    QueryList(){
      this.getList();
        // 调用获取自定义表头
        this.getHeader();
    },
    // 清空
    empty(){
      this.where = {
        page: 1, // 当前页数
        limit: 5 // 每页显示条目个数
      }
        // 调用获取自定义表头
        this.getHeader();
      this.getList();
    },

    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
        // 调用获取自定义表头
        this.getHeader();
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
        // 调用获取自定义表头
        this.getHeader();
      this.getList()
    },

    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name"
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #FAFAFA"
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name"
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },
    // 仅对 type=selection 的列有效，Function(row, index) 用来决定这一行的 CheckBox 是否可以勾选
    selectable(row, index) {
      if (row.sex == "女") {
        return false
      } else {
        return true
      }
    },

    // 启用状态
    Enable(row){
      // 参数
      let params = {
        status: '1'
      }
      set_statusApi(row.id,params).then(res => {
        this.$message.success(res.msg);
        this.getList();
      }).catch(err => {
        this.$message.error(err.msg);
      })
    },
    Disable(row){
      // 参数
      let params = {
        status: '0'
      }
      set_statusApi(row.id,params).then(res => {
        this.$message.success(res.msg);
        this.getList();
      }).catch(err => {
        this.$message.error(err.msg);
      })
    },

    // 删除
    del(row){
      console.log(row);
      this.$utils.confirm("确定要删除吗？", function () {
        // 执行一些需要的逻辑
        console.log('点击确定')
      }, "确定", 'warning');
    },

    // 点击列表设置
    ListSetup(){
      this.ListSetupShow = true;
    },
    // 列表设置关闭
    handleClose(){
      this.ListSetupShow = false;
    },
    //事件  拿到从组件内部处理的数据顺序并赋值
    SortList(value){
      // 接收子组件传递的值替换当前列表显示顺序
      this.columns = value;
    },
    dialogshow(value){
      this.ListSetupShow = value;
    },

    // 跳转详情
    Todetails(){
      // 显示详情子组件
      this.Orderdetails = true;
    },
    // 接收 详情子组件传递的值
    ShowDetalis(value){
      console.log(value)
      // 隐藏详情子组件
      this.Orderdetails = false;
        // 调用获取自定义表头
        this.getHeader();
      this.getList();
    },

    clickactive(item,index){
      this.isShow = index;
    },

    //点击审核按钮
    ToExamine(){
      this.showorderReview = true;
    },

  }

}
</script>

<style lang="scss" scoped>
.table_div{
  border-bottom: 1px solid #eeeeee;;
  margin-bottom: 20px;
}
.table_div_kuai{
  display: inline-block;
  font-size: 20px;
  font-weight: 500;
  color: #7b7b7b;
  margin: 15px;
  margin-bottom: 0;
  cursor: pointer;
  padding-bottom: 10px;
}
.table_div_kuai:hover{
  display: inline-block;
  font-size: 20px;
  font-weight: 700;
  color: #050505;
  border-bottom: 2px solid #FF9B05;
}
.table_div_kuai1{
  display: inline-block;
  font-size: 20px;
  font-weight: 700;
  color: #050505;
  border-bottom: 2px solid #FF9B05;
}
</style>
